<script setup lang="ts">
import { computed, ref } from "vue";
import { useForm } from "@inertiajs/vue3";
import AppForm from "@/Components/Shared/forms/AppForm.vue";
import AppFormGroup from "@/Components/Shared/forms/AppFormGroup.vue";
import AppFormInputText from "@/Components/Shared/forms/AppFormInputText.vue";
import AppFormInputPassword from "@/Components/Shared/forms/AppFormInputPassword.vue";
import AppFormInputCheckbox from "@/Components/Shared/forms/AppFormInputCheckbox.vue";
import AppFormFooter from "@/Components/Shared/forms/AppFormFooter.vue";
import AppButtonGroup from "@/Components/Shared/button/AppButtonGroup.vue";
import AppButton from "@/Components/Shared/button/AppButton.vue";
import AppLink from "@/Components/Shared/link/AppLink.vue";
import AppAlert from "@/Components/Shared/alert/AppAlert.vue";
import GoogleRecaptchaV3 from "@/Components/App/forms/GoogleReCaptchaV3.vue";
import AppArticle from "@/Components/Shared/article/AppArticle.vue";
import AppDivider from "@/Components/Shared/divider/AppDivider.vue";

type Props = {
    canResetPassword?: boolean;
    showRegistrationLink?: boolean;
    showSocialLogin?: boolean;
    withRecaptcha?: boolean;
};

const {
    canResetPassword = true,
    showRegistrationLink = true,
    showSocialLogin = true,
    withRecaptcha = false,
} = defineProps<Props>();

const emit = defineEmits([
    "success",
    "close",
    "beforeUnload",
    "cancelBeforeUnload",
]);

const captcha = ref(null);
const form = useForm({
    email: "",
    password: "",
    remember: false,
    recaptcha: "",
});

// computed
const goodToGo = computed(() => {
    return !!form.email && !!form.recaptcha;
});

// methods
const executeCaptcha = () => {
    captcha.value?.execute();
};

const handleSubmit = () => {
    form.transform((data: any) => ({
        ...data,
        remember: form.remember ? "on" : "",
    })).post("/login", {
        onError: () => {
            // refresh captcha
            executeCaptcha();
        },
        onFinish: () => {
            form.reset("password");

            // refresh captcha
            executeCaptcha();
        },
        onSuccess: () => {
            emit("success");
            emit("close");
        },
    });
};
const handlePasswordInputChange = (event: any) => {
    if (!form.password) {
        form.password = event.target.value;
    }
};
</script>

<template>
    <template v-if="showSocialLogin">
        <AppButtonGroup
            size="full"
            class="mb-4"
        >
            <AppLink
                href="/login/google"
                variant="button"
                as="a"
            >
                Inloggen met Google
            </AppLink>
        </AppButtonGroup>

        <AppDivider
            word="of"
            spacing="small"
        />
    </template>

    <AppForm @submit.prevent="handleSubmit">
        <AppFormGroup>
            <AppFormInputText
                v-model="form.email"
                autofocus
                :errors="form.errors.email"
                label="E-mailadres"
                name="email"
                type="email"
                required
                @update:model-value="delete form.errors.email"
            />

            <AppFormInputPassword
                v-model="form.password"
                autocomplete="current-password"
                :errors="form.errors.password"
                label="Wachtwoord"
                name="password"
                required
                type="password"
                hide-hint
                @change="handlePasswordInputChange"
                @update:model-value="delete form.errors.password"
            />

            <AppFormInputCheckbox
                v-model="form.remember"
                :binary="true"
                name="remember"
            />
        </AppFormGroup>

        <GoogleRecaptchaV3
            id="login_id"
            ref="captcha"
            v-model="form.recaptcha"
            style="display: none"
            class="recaptcha"
            site-key="6Leil4gpAAAAAMkrPWdVnHqLkESkt4BqA2Pi5_JT"
            :inline="true"
            action="login"
        />

        <AppFormFooter>
            <AppButtonGroup :variant="['lt-sm:column', 'sm:spaced']">
                <AppLink
                    v-if="canResetPassword"
                    href="/forgot-password"
                >
                    Wachtwoord vergeten?
                </AppLink>

                <AppButton
                    :disabled="!goodToGo"
                    :loading="form.processing"
                    type="submit"
                    color="primary"
                >
                    Inloggen
                </AppButton>
            </AppButtonGroup>
        </AppFormFooter>
    </AppForm>

    <AppAlert
        v-if="showRegistrationLink"
        class="mt-12"
    >
        Nog geen lid?
        <AppLink
            href="/register"
            variant="underlined"
            color="current"
        >
            Registreer je nu
        </AppLink>
    </AppAlert>

    <AppArticle
        class="mt-4"
        size="small"
    >
        Deze website wordt beschermd door reCAPTCHA, en de Google
        <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener"
            >Privacy Policy</a
        >
        en
        <a
            href="https://policies.google.com/terms"
            target="_blank"
            rel="noopener"
            >Terms of Service</a
        >
        zijn van toepassing.
    </AppArticle>
</template>
