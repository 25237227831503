<script setup lang="ts">
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";
import { computed, useAttrs, useSlots } from "vue";
import AppFormInputError from "@/Components/Shared/forms/AppFormInputError.vue";
import AppFormField from "@/Components/Shared/forms/AppFormField.vue";
import { defaultFormEmits, defaultInputProps } from "@/Utils/form";
import AppFormFieldLabel from "@/Components/Shared/forms/AppFormFieldLabel.vue";
import AppText, { TextColor } from "@/Components/Shared/text/AppText.vue";

type Props = {
    binary?: boolean;
    modelValue:
        | boolean
        | number
        | string
        | Array<any>
        | Record<string, any>
        | null;
    value?: string | number;
    labelClass?: string;
    inputClass?: string;
    toggle?: boolean;
    id?: string;
    hintColor?: TextColor;
} & defaultInputProps;

const {
    binary = false,
    disabled = false,
    modelValue = undefined,
    value = undefined,
    labelClass = undefined,
    inputClass = undefined,
    toggle = false,
    id = undefined,
    hintColor = "grayLight",
} = defineProps<Props>();

const attrs = useAttrs();

const classes: AntlerClasses<Props> = {
    base: `h-5 w-5 basis-5 shrink-0 rounded border-gray-300 text-theme focus:ring-theme disabled:text-gray-300 disabled:ring-gray-300 disabled:bg-gray-100 ${inputClass ? inputClass : ""}`,
};
const { aClass } = installAntlerComponent("input-checkbox", {}, classes, attrs);

// computed
const checked = computed(() => {
    if (binary) {
        return modelValue;
    }
    if (Array.isArray(modelValue)) {
        return modelValue.includes(value);
    }

    return modelValue === value;
});

// methods
const onChange = () => {
    if (!attrs.disabled) {
        let newModelValue;

        if (binary) {
            newModelValue = !modelValue;
        } else {
            if (checked.value) {
                // newModelValue = modelValue.filter(val => val !== value);
                newModelValue = (modelValue as Array<any>).filter(val => val !== value);
            } else {
                // newModelValue = modelValue ? [...modelValue, value] : [value];
                newModelValue = modelValue ? [...modelValue as Array<any>, value] : [value];
            }
        }
        emits("update:modelValue", newModelValue);
    }
};

const emits = defineEmits(defaultFormEmits);

const slots = useSlots();
</script>

<template>
    <AppFormField
        v-if="label || slots.default"
        :for="name"
        @change="onChange"
    >
        <AppFormFieldLabel
            :for="id || name"
            class="w-full mb-0"
            :class="labelClass"
        >
            <input
                :id="id || name"
                ref="input"
                :class="
                    aClass({
                        'border-red-500': errors,
                        'sr-only peer': toggle && binary,
                    })
                "
                type="checkbox"
                :name="name"
                :disabled="disabled"
                :checked="checked"
                :value="value"
                :required="required"
            />
            <div
                v-if="toggle && binary"
                class="w-11 h-6 bg-slate-300 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 dark:peer-focus:ring-theme/10 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-theme"
            />

            <span
                v-if="slots.default"
                class="ml-2"
            >
                <slot />
            </span>
            <span
                v-else-if="label"
                class="ml-2"
            >
                {{ label }}
            </span>
        </AppFormFieldLabel>

        <AppFormInputError
            v-if="errors && errors.length"
            :errors="errors"
        />
        <AppText
            v-else-if="hint"
            class="mt-1"
            :color="hintColor"
            size="small"
        >
            {{ hint }}
        </AppText>
    </AppFormField>
</template>
