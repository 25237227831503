<template>
    <div :class="aClass()">
        <div
            class="absolute inset-0 flex items-center"
            aria-hidden="true"
        >
            <div
                :class="[
                    'w-full border-t',
                    {
                        'border-themeWhite': color?.includes('themeWhite'),
                        'border-light': color?.includes('light'),
                        'border-dark': color?.includes('dark'),
                    },
                ]"
            />
        </div>
        <div
            v-if="word || $slots.default"
            class="relative flex justify-center"
        >
            <span
                v-if="word"
                :class="wordBg"
                >{{ word }}</span
            >
            <template v-else-if="$slots.default">
                <slot />
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import {
    AntlerClasses,
    installAntlerComponent,
    responsive,
} from "@/Utils/component";
import { computed } from "vue";

export type DividerSpacing = "small" | "regular" | "large";
export type DividerColor = "white" | "light" | "dark";

type Props = {
    word?: string;
    spacing?: DividerSpacing;
    color?: DividerColor;
};

const {
    word = undefined,
    spacing = undefined,
    color = undefined,
} = defineProps<Props>();

const classes: AntlerClasses<Props> = {
    base: "flex justify-center items-center relative py-8 w-full",
    variants: {
        spacing: {
            small: responsive("py-4"),
            regular: responsive("py-8"),
            large: responsive("py-16"),
        },
    },
};
const { aClass } = installAntlerComponent(
    "divider",
    { spacing, color },
    classes,
);

const wordBg = computed(() => {
    if (color?.includes("themeWhite")) {
        return "px-2 text-sm text-gray-500 bg-themeWhite";
    }

    return "px-2 text-sm text-gray-500 bg-white";
});
</script>
